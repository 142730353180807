import $ from 'jquery';
import VariantStockForm from './variant-stock-form';
export default class CustomizableBundleProducts {
    static $products = $('.js__customizable-bundle-product');
    static $tradeInButton = $('.js__product-variant-color-select-button');
    static $submitButton = $('.js__customizable-bundle-submit-btn');
    static $storesForm = $('.js__variant-stock-form');
    static init() {
        this.$products = $('.js__customizable-bundle-product');
        this.$products.each((index, element) => {
            const $product = $(element);
            const $colorSelect = $product.find('.js__customizable-bundle-product-color-select');
            $colorSelect.on('change', event => {
                const $checkedInput = $(event.currentTarget);
                this.setColorLabel($product, $checkedInput.data('color-label'));
                this.setProductImage($product, $checkedInput.data('image'));
                this.toggleStockError($product, !!$checkedInput.data('is-in-stock'));
                this.toggleButtons();
                if (this.$storesForm.length) {
                    VariantStockForm.setSelectedVariants(this.getSelectedProductVariants());
                }
            });
            setTimeout(() => {
                $colorSelect.each((index, element) => {
                    const $element = $(element);
                    if ($element.is(':checked')) {
                        $element.trigger('change');
                    }
                });
            });
        });
    }
    static getSelectedProductVariants() {
        return this.$products
            .find('.js__customizable-bundle-product-color-select')
            .filter(':checked')
            .toArray()
            .map(element => $(element).val());
    }
    static toggleStockErrorByColorSelect($colorSelect, isInStock) {
        const $product = $colorSelect.closest('.js__customizable-bundle-product');
        this.toggleStockError($product, isInStock);
    }
    static setColorLabel($product, colorLabel) {
        const $colorLabel = $product.find('.js__customizable-bundle-product-color-label');
        $colorLabel.text(colorLabel);
    }
    static setProductImage($product, url) {
        const $productImage = $product.find('.js__customizable-bundle-product-image');
        $productImage.attr('src', url);
        $productImage.siblings('source').first().attr('srcset', url);
    }
    static toggleStockError($product, isInStock) {
        const $stockError = $product.find('.js__customizable-bundle-product-stock-error');
        $stockError.toggleClass('text-red js__variant-error', !isInStock);
        if (!isInStock) {
            $stockError.text($stockError.data('stock-error-text'));
        }
    }
    static toggleButtons() {
        const isAnyErrorExist = $('.js__customizable-bundle-product-stock-error.js__variant-error').length;
        this.$tradeInButton.prop('disabled', isAnyErrorExist);
        this.$submitButton.prop('disabled', isAnyErrorExist);
    }
}
