import $ from 'jquery';
import SingleProductSlider from '../../../components/single-product-slider';
import SingleProductFullscreenSlider from '../../../components/single-product-fullscreen-slider';
import SingleProductForm from './single-product-form';
import GaTracking from '../../../core/components/ga-tracking';
import VariantStockForm from './variant-stock-form';
export default class SingleProductColorSelect {
    static $colors = $('.js__single-product-color-select');
    static $title = $('.js__single-product-color-title');
    static $preorderMessage = $('.js__single-product-preorder-message');
    static $labelWrapper = $('.js__product-labels');
    static $labelTranslations = $('.js__label-translations');
    static colorMapping = {
        new: 'badge-accent',
        limited_edition: 'badge-blue',
        pre_order: 'badge-yellow',
        out_of_stock: 'badge-error-red badge-outline',
        bundle: 'badge-amber',
        sale: 'badge-deep-red',
        available_with_trade_in: 'badge-accent',
        recycled: 'badge-amber',
        most_popular: 'badge-accent',
    };
    static init() {
        this.$colors = $('.js__single-product-color-select');
        this.$title = $('.js__single-product-color-title');
        this.$preorderMessage = $('.js__single-product-preorder-message');
        this.$labelWrapper = $('.js__product-labels');
        this.$labelTranslations = $('.js__label-translations');
        this.$colors.on('change', () => {
            this.handleColorChange();
        });
        this.handleColorChange();
    }
    static getSelectedColorElement() {
        return this.$colors.filter(':checked');
    }
    static getSelectedProductVariant() {
        return this.getSelectedColorElement().val();
    }
    static handleColorChange() {
        const $selectedColor = this.getSelectedColorElement();
        const labels = $selectedColor.data('labels');
        let $submitButton = $selectedColor.closest('form').find('.js__add-to-cart-btn, .js__submit-modal-form');
        $submitButton = $submitButton.length ? $submitButton : $('.js__add-to-cart-btn, .js__submit-modal-form');
        const $variantStockForm = $('.js__variant-stock-form');
        const $stickyBarSubmitButton = $('.js__product-sticky-bar-button');
        const $tradeInButton = $('.js__product-variant-color-select-button');
        const shouldForceButtonDisable = $submitButton.data('force-disable') || false;
        const shouldForceTradeInButtonDisable = $tradeInButton.data('force-disable') || false;
        if (this.$colors.length) {
            this.$labelWrapper.empty();
        }
        if (labels) {
            const labelArray = labels.length > 0 ? labels.split(', ') : [];
            const isOutOfStock = labelArray.includes($selectedColor.data('disable-submit-on-label'));
            this.pushStockDataLayerEventByColor(isOutOfStock, $selectedColor);
            $submitButton.prop('disabled', shouldForceButtonDisable || isOutOfStock);
            $stickyBarSubmitButton.prop('disabled', shouldForceButtonDisable || isOutOfStock);
            $tradeInButton.prop('disabled', shouldForceTradeInButtonDisable || isOutOfStock);
            $variantStockForm.find('input, select').prop('disabled', shouldForceButtonDisable || isOutOfStock);
            if (labelArray.length > 0) {
                labelArray.forEach((label) => {
                    this.$labelWrapper.append(`<li class="badge ${this.colorMapping[label]}">${this.$labelTranslations.data('labels')[label]}</li>`);
                });
            }
        }
        else {
            $submitButton.prop('disabled', shouldForceButtonDisable);
            $stickyBarSubmitButton.prop('disabled', shouldForceButtonDisable);
            $tradeInButton.prop('disabled', shouldForceTradeInButtonDisable);
            $variantStockForm.find('input, select').prop('disabled', shouldForceButtonDisable);
            this.pushStockDataLayerEventByColor(false, $selectedColor);
        }
        if (!$selectedColor.length) {
            if (SingleProductSlider.isSliderExist()) {
                SingleProductSlider.showAllSlides();
            }
            if (SingleProductFullscreenSlider.isFullscreenExist()) {
                SingleProductFullscreenSlider.showAllSlides();
            }
            return;
        }
        this.setTitleByColor($selectedColor);
        this.setStickyBarImageByColor($selectedColor.val());
        this.togglePreorderState($selectedColor);
        this.pushDataLayerEventByColor($selectedColor);
        SingleProductSlider.showSlidesByVariantCode($selectedColor.val());
        if (SingleProductFullscreenSlider.isFullscreenExist()) {
            SingleProductFullscreenSlider.showSlidesByVariantCode($selectedColor.val());
        }
        VariantStockForm.setSelectedVariants([String($selectedColor.val())]);
    }
    static setTitleByColor($color) {
        this.$title.text($color.data('name'));
    }
    static setStickyBarImageByColor(variantCode) {
        const $images = $('.js__sticky-bar-image');
        const $visibleImage = $(`.js__sticky-bar-image[data-variant-code="${variantCode}"]`);
        $images.hide();
        $visibleImage.show();
    }
    static togglePreorderState($color) {
        this.$preorderMessage.toggleClass('d-none', !$color.data('preorder'));
    }
    static pushStockDataLayerEventByColor(isOutOfStock, $colorElement) {
        const datalayer = $colorElement.data('color-change-datalayer') || {
            event: 'gtmZoneEvent',
            eventCategory: 'Product page',
            eventAction: 'Message',
            eventLabel: isOutOfStock ? 'Out of Stock' : 'In Stock',
            eventValue: 0,
            eventNonInteraction: true,
        };
        GaTracking.pushDataLayer({
            ...datalayer,
            eventCategory: $('.js__ga-event-data').first().data('event-category') || datalayer.eventCategory,
            eventLabel: isOutOfStock ? 'Out of Stock' : 'In Stock',
        });
    }
    static pushDataLayerEventByColor($color) {
        if ($color.data('layer-id')) {
            GaTracking.pushDataLayer({
                event: 'productDetailsView',
                ecommerce: {
                    detail: {
                        products: [
                            {
                                name: SingleProductForm.$form.data('name'),
                                id: $color.data('layer-id'),
                                price: SingleProductForm.$form.data('price'),
                                brand: 'IQOS',
                                category: SingleProductForm.$form.data('category'),
                                variant: $color.data('label-en'),
                                dimension51: '',
                                dimension52: 'In Stock',
                                dimension54: '',
                                dimension55: '',
                                metric11: SingleProductForm.$form.data('metric'),
                            },
                        ],
                    },
                },
            });
        }
    }
}
