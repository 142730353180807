import $ from 'jquery';
import SingleProductForm from './single-product-form';
export default class ProductTradeIn {
    static $button = $('.js__product-variant-color-select-button');
    static baseUrl = ProductTradeIn.$button.data('base-url');
    static productVariantQueryParamKey = ProductTradeIn.$button.data('product-variant-query-param-key');
    static fallbackVariantCode = ProductTradeIn.$button.data('fallback-variant-code');
    static init() {
        this.$button.on('click', () => {
            if (!SingleProductForm.isValid()) {
                return;
            }
            window.location.assign(this.getUrl());
        });
    }
    static enable() {
        this.$button.prop('disabled', false);
    }
    static disable() {
        this.$button.prop('disabled', true);
    }
    static getUrl() {
        return `${this.baseUrl}&${$.param({
            [this.productVariantQueryParamKey]: SingleProductForm.getSelectedProductVariants() || this.fallbackVariantCode,
        })}`;
    }
}
