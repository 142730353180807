import $ from 'jquery';
import Utils from '../../../utils';
import { hideButtonLoader, showButtonLoader } from '../../../utils/button-loader';
var StockStatus;
(function (StockStatus) {
    StockStatus["OutOfStock"] = "out_of_stock";
    StockStatus["LastPieces"] = "last_pieces";
    StockStatus["InStock"] = "in_stock";
})(StockStatus || (StockStatus = {}));
export default class VariantStockForm {
    static $form = $('.js__variant-stock-form');
    static $citySelectInput = this.$form.find('select');
    static resellers = [];
    static selectedVariants = [this.$form.data('variants')];
    static init() {
        if (!this.$form.length) {
            return;
        }
        this.updateCityList();
        this.$citySelectInput.on('change', () => this.updateStoreList());
    }
    static setSelectedVariants(variants) {
        this.selectedVariants = variants;
        this.updateStoreList();
        this.updateResellerList();
    }
    static updateResellerList() {
        if (!this.$form.length || !this.selectedVariants.length || this.selectedVariants.length > 1) {
            this.resellers = [];
            return;
        }
        const resellerListUrl = this.$form.data('reseller-list-url');
        const $resellerWrapper = $(this.$form.data('resellers-content-load-selector'));
        const $loaderWrapper = $('.js__loader-wrapper');
        Utils.contentLoader($loaderWrapper, true);
        $.ajax({
            method: 'GET',
            url: resellerListUrl.replace('variant-code', this.selectedVariants),
        })
            .done((data) => {
            if (!data.length) {
                $resellerWrapper.closest('.js__resellers-wrapper').addClass('d-none');
                return;
            }
            else {
                $resellerWrapper.closest('.js__resellers-wrapper').removeClass('d-none');
            }
            this.resellers = data;
            const resellers = data.map(reseller => {
                return $(`<div class="col-4 col-lg-3 text-center"><img src="${reseller.image}" alt="${reseller.name}" class="mb-3 pdp-reseller-logo"></div>`);
            });
            $resellerWrapper.html('');
            resellers.forEach(reseller => {
                $resellerWrapper.append(reseller);
            });
        })
            .fail(xhr => {
            $resellerWrapper.html('');
            $('.js__stock-general-error').removeClass('d-none');
        })
            .always(() => {
            Utils.contentLoader($loaderWrapper, false);
        });
    }
    static updateStoreList() {
        const $citySelectInput = this.$form.find('.js__stock-city-select-input');
        const $storesWrapper = $(this.$form.data('stores-content-load-selector'));
        const $loaderWrapper = $('.js__loader-wrapper');
        if (!this.$form.length || !$citySelectInput.val() || !this.selectedVariants.length) {
            $storesWrapper.html('');
            return;
        }
        const data = { variantCodes: this.selectedVariants, city: $citySelectInput.val() };
        Utils.contentLoader($loaderWrapper, true);
        $.ajax({
            method: this.$form.attr('method'),
            url: this.$form.attr('action'),
            data: data,
        })
            .done((data) => {
            const stores = data.map((store, index) => {
                const $statusText = $('<div class="font-weight-bold mb-3"></div>');
                if (store.status === StockStatus.OutOfStock) {
                    $statusText.append(this.$form.data('out-of-stock-label'));
                    $statusText.addClass('text-gray');
                }
                else if (store.status === StockStatus.LastPieces) {
                    $statusText.append(this.$form.data('last-pieces-label'));
                    $statusText.addClass('text-red');
                }
                else {
                    $statusText.append(this.$form.data('in-stock-label'));
                }
                const isLastStore = index === data.length - 1;
                const $storeWrapper = $(`<div class="font-size-small ${!isLastStore || this.resellers.length ? 'border-bottom' : ''} pb-3 py-3 ${store.status === StockStatus.OutOfStock ? 'text-gray' : ''}">
                <div>${store.name}</div>
            </div>`);
                $storeWrapper.append($statusText);
                $storeWrapper.append(`<div>${store.address}</div>`);
                return $storeWrapper;
            });
            $storesWrapper.html('');
            stores.forEach(store => {
                $storesWrapper.append(store);
            });
        })
            .fail(xhr => {
            $storesWrapper.html('');
            $('.js__stock-general-error').removeClass('d-none');
        })
            .always(() => {
            Utils.contentLoader($loaderWrapper, false);
        });
    }
    static updateCityList() {
        const $citySelectInput = this.$form.find('.js__stock-city-select-input');
        const $dropdownArrow = this.$form.find('.select2-selection__arrow');
        if (!this.$form.length) {
            return;
        }
        showButtonLoader($dropdownArrow);
        $.ajax({
            method: this.$form.attr('method'),
            url: this.$form.data('city-list-url'),
        })
            .done((data) => {
            const cities = data.map(city => {
                return $(`<option value="${city.id}">${city.name}</option>`);
            });
            $citySelectInput.html('');
            $citySelectInput.append('<option></option>');
            cities.forEach(city => {
                $citySelectInput.append(city);
            });
        })
            .fail(xhr => {
            $('.js__stock-general-error').removeClass('d-none');
        })
            .always(() => {
            hideButtonLoader($dropdownArrow);
        });
    }
}
