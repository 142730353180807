import $ from 'jquery';
import { hideButtonLoader, showButtonLoader } from '../../../utils/button-loader';
import CartItemsCount from './cart-items-count';
import CartPopup from './cart-popup';
import SingleProductColorSelect from './single-product-color-select';
import ProductTradeIn from './product-trade-in';
import CustomizableBundleProducts from './customizable-bundle-products';
import GaTracking from '../../../core/components/ga-tracking';
import { scrollToElement } from '../../../utils/scroll-to-element';
export default class SingleProductForm {
    static $form = $('.js__single-product-form');
    static $formError = SingleProductForm.$form.find('.js__single-product-form-error');
    static $formOutOfStockError = SingleProductForm.$form.find('.js__single-product-stock-error');
    static $formItemLimitExceededError = SingleProductForm.$form.find('.js__single-item-limit-error');
    static $formItemPurchaseRulesLimitExceededError = SingleProductForm.$form.find('.js__single-item-purchase-rules-limit-error');
    static $quantityInput = SingleProductForm.$form.find('.js__single-product-quantity-input');
    static $submitButton = SingleProductForm.$form.find(':submit');
    static isCustomizableBundle = SingleProductForm.$form.data('is-customizable-bundle');
    static init() {
        this.$form.find('[data-toggle="tooltip"]').tooltip();
        this.$form.on('submit', event => {
            if (!this.$form.data('submit-without-ajax')) {
                event.preventDefault();
            }
            if (this.isValid()) {
                this.submitForm();
            }
        });
    }
    static isValid() {
        return !this.$form.data('validator') || this.$form.valid();
    }
    static getSelectedProductVariants() {
        return this.isCustomizableBundle
            ? CustomizableBundleProducts.getSelectedProductVariants()
            : SingleProductColorSelect.getSelectedProductVariant();
    }
    static submitForm() {
        const $tradeInButton = $('.js__product-variant-color-select-button');
        const shouldForceTradeInButtonDisable = $tradeInButton.data('force-disable') || false;
        showButtonLoader(this.$submitButton);
        ProductTradeIn.disable();
        this.$formError.addClass('d-none');
        this.$formOutOfStockError.addClass('d-none');
        this.pushAddToCartDataLayerEvent();
        if (this.$form.data('submit-without-ajax')) {
            return;
        }
        $.ajax({
            type: this.$form.attr('method'),
            url: this.$form.attr('action'),
            data: this.$form.serialize(),
            beforeSend: () => {
                this.$formItemLimitExceededError.addClass('d-none');
                this.$formItemPurchaseRulesLimitExceededError.addClass('d-none');
                this.$formOutOfStockError.addClass('d-none');
                this.$formError.addClass('d-none');
            },
        }).then(({ quantity, content }) => {
            hideButtonLoader(this.$submitButton);
            if (!shouldForceTradeInButtonDisable) {
                ProductTradeIn.enable();
            }
            CartPopup.populateAndOpenPopup(content);
            CartItemsCount.set(quantity);
            this.$quantityInput.val(1).trigger('change');
        }, data => {
            const errors = data.responseJSON?.errors?.errors || [];
            if (this.isCustomizableBundle) {
                errors.forEach(({ fieldId }) => {
                    const $input = $(`input[id^=${fieldId}]`);
                    CustomizableBundleProducts.toggleStockErrorByColorSelect($input, false);
                });
            }
            else if (errors.length) {
                if (this.$formItemLimitExceededError.data('error-message').includes(errors[0].error)) {
                    this.$formItemLimitExceededError.removeClass('d-none');
                }
                else if (this.$formItemPurchaseRulesLimitExceededError.data('error-message').includes(errors[0].error)) {
                    this.$formItemPurchaseRulesLimitExceededError.removeClass('d-none');
                }
                else if (this.$formOutOfStockError.data('error-message').includes(errors[0].error)) {
                    this.$formOutOfStockError.removeClass('d-none');
                }
                else {
                    this.$formError.removeClass('d-none');
                }
            }
            else {
                this.$formError.removeClass('d-none');
            }
            hideButtonLoader(this.$submitButton);
            if (!shouldForceTradeInButtonDisable) {
                ProductTradeIn.enable();
            }
            if (!this.isCustomizableBundle && errors.length) {
                this.$submitButton.first().prop('disabled', true);
                scrollToElement(this.$formItemLimitExceededError);
            }
            GaTracking.pushDataLayer({
                event: 'popupDisplay',
                popupDisplayContent: 'Add to cart error',
            });
        });
    }
    static pushAddToCartDataLayerEvent() {
        // TODO: clarify about id and variant fields, what we should use for bundles?
        const $selectedColor = SingleProductColorSelect.getSelectedColorElement();
        GaTracking.pushDataLayer({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'EUR',
                add: {
                    products: [
                        {
                            name: this.$form.data('name'),
                            id: $selectedColor.data('layer-id'),
                            price: this.$form.data('price'),
                            brand: 'IQOS',
                            category: this.$form.data('category'),
                            variant: $selectedColor.data('label-en'),
                            quantity: this.$quantityInput.val() || 1,
                            dimension51: '',
                            dimension52: 'In Stock',
                            dimension54: '',
                            dimension55: '',
                            metric11: this.$form.data('metric'),
                        },
                    ],
                },
            },
        });
    }
}
