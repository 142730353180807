import $ from 'jquery';
import SwiperCore, { Pagination, EffectFade } from 'swiper/core';
import Swiper from 'swiper';
import LazyImages from '../components/lazy-images';
export default class SingleProductFullscreenSlider {
    static $slider = $('.js__single-product-fullscreen-slider');
    static $productExpandBtn = $('.js__product-image-expand-btn');
    static $productFullscreenCloseBtn = $('.js__product-fullscreen-popup-close-btn');
    static $productFullscreen = $('.js__product-fullscreen-popup');
    static $slides = SingleProductFullscreenSlider.$slider.find('.swiper-slide');
    static swiper;
    static options = {
        centeredSlides: true,
        centerInsufficientSlides: false,
        slidesPerView: 'auto',
        spaceBetween: 0,
        watchOverflow: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        speed: 1100,
    };
    static init() {
        if (!this.$slider.length) {
            return;
        }
        this.$slides.remove();
        SwiperCore.use([Pagination, EffectFade]);
        this.swiper = new Swiper(this.$slider.get(0), this.options);
        this.$productExpandBtn.on('click', () => {
            window.history.pushState({ isFullscreen: 1 }, '', '?is-fullscreen=1');
            this.openFullscreen();
        });
        this.$productFullscreenCloseBtn.on('click', () => {
            window.history.back();
            this.closeFullscreen();
        });
        addEventListener('popstate', (event) => {
            const state = event.state;
            if (!state) {
                this.closeFullscreen();
            }
            else if (state && state.isFullscreen) {
                this.openFullscreen();
            }
        });
        const urlParams = new URLSearchParams(window.location.search);
        const isFullscreenOpened = !!parseInt(urlParams.get('is-fullscreen') || '0');
        if (isFullscreenOpened) {
            this.openFullscreen();
        }
    }
    static closeFullscreen() {
        this.$productFullscreen.removeClass('active');
        $('body').removeClass('modal-open');
    }
    static openFullscreen() {
        this.$productFullscreen.addClass('active');
        $('body').addClass('modal-open');
    }
    static slideTo(index) {
        this.swiper.slideTo(index - 1);
    }
    static showAllSlides() {
        this.showSlides(this.$slides);
    }
    static isFullscreenExist() {
        return $('.js__product-image-expand-btn').length > 0;
    }
    static showSlidesByVariantCode(variantCode) {
        const $slidesByVariantCode = this.$slides.filter((index, slide) => String($(slide).data('variant-code')) === variantCode);
        this.showSlides($slidesByVariantCode);
    }
    static showSlides($slides) {
        this.swiper.removeAllSlides();
        this.swiper.appendSlide($slides.toArray());
        this.swiper.slideToLoop(0, 0);
        $(this.swiper.pagination.el).toggleClass('invisible', $slides.length === 1);
        LazyImages.update();
    }
}
